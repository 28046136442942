require('bootstrap-star-rating');
const myUjs = require('my_ujs.js');

document.addEventListener("turbolinks:load", function() {
  let start_time = Date.now();

  // This tries to do fewer updates particularly in the readonly case
  $('.star-rating:not(.readonly)').rating({
    theme: 'krajee-uni',
    showCaption: false,
    showClear: false,
    min: 0,
    max: 5,
    step: 1,
    size: 'xs',
    filledStar: '&#x2605;',
    emptyStar: '&#x2606;',
    clearButton: '&#x229d;'
  });
  
  $('.star-rating.manually-set:not(.readonly)').rating('refresh', {
    containerClass: 'manually-set'
  });

  $('.star-rating.readonly:not(.manually-set)').rating({
    readonly: true,
    theme: 'krajee-uni',
    showCaption: false,
    showClear: false,
    min: 0,
    max: 5,
    step: 1,
    size: 'xs',
    filledStar: '&#x2605;',
    emptyStar: '&#x2606;',
    clearButton: '&#x229d;'
  });

  $('.star-rating.readonly.manually-set').rating({
    readonly: true,
    theme: 'krajee-uni',
    showCaption: false,
    showClear: false,
    min: 0,
    max: 5,
    step: 1,
    size: 'xs',
    containerClass: 'manually-set',
    filledStar: '&#x2605;',
    emptyStar: '&#x2606;',
    clearButton: '&#x229d;'
  });

  $('.star-rating:not(.readonly)').on('rating:change', function(event, value, caption) {
    var rdc = event.target.dataset.rdc;

    // Prevent race conditions
    $('.star-rating').rating('refresh', {disabled: true});

    // TODO even more hacky than on the other page!
    $('#rated-rdc').val(rdc);
    $('#rdc-stars').val(value);
    myUjs.submitNoScroll('personal-list-form');
  });

  console.log("Star setup time (ms):", Date.now() - start_time);
});
