const myUjs = require('my_ujs.js');

document.addEventListener("turbolinks:load", function() {
  $('[data-delete]').on('click', function(e) {
    let targeted = document.getElementById(e.target.dataset.delete);

    if (!targeted) return;

    targeted.remove();

    let formid = e.target.dataset.submit;
    if (formid) {
      myUjs.submitNoScroll(formid);
    }
  });
});
