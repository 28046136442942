require('typeahead.js');

const myUjs = require('my_ujs.js');
const FuzzySearch = require('fz-search');

// Cache indexed program list between turbolinks loads
const build_fuzzy_searcher = fetch('/programs') // TODO polyfill
  .then(function(res) {
    return res.json();
  })
  .then(function(progs) {
    return new FuzzySearch({
      source: progs,
      keys: ['name', 'tld', 'spec', 'city']
    });
  });

document.addEventListener('turbolinks:load', function() {
  build_fuzzy_searcher.then(function(searcher) {
    $('.program-select').typeahead(
      {
        hint: false,
        minLength: 2,
        highlight: false, // let FuzzySearch handle highlight
      },
      {
        name: 'programs',
        source: searcher,
        display: 'name',
        limit: 12,
        templates: {
          suggestion: function(result) {
            return "<span>"
              + searcher.highlight(result.name)
              + "</span>";
          }
        }
      }
    );

    // Focus the thing if we should. Needs to be done now after the input has been meddled with
    // by typeahead
    $('[autofocus]').focus();
  });

  // TODO should be somewhere else?
  $('#program-to-add').bind('typeahead:select', function(ev, program) {
    // TODO pretty hacky
    $('#program-to-add-rdc').val(program.rdc);
    myUjs.submitNoScroll('personal-list-form');
  });
});
