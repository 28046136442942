const myUjs = require('my_ujs.js');
const sortable = require('html5sortable');

document.addEventListener("turbolinks:load", function() {
  if (window.last_sort_stop) {
    console.log("ms since last sort stop: ", Date.now() - window.last_sort_stop);
    window.last_sort_stup = undefined;
  }

  let start_time = Date.now();
  sortable('.draggable-list', {
    items: '.draggable-item',
    handle: '.draggable-handle',
    forcePlaceholderSize: true,
  });

  $('.draggable-list:not(.atomic-draggable-list)').on('sortupdate', function(e) {
    // Upload the new order by just submitting the form
    myUjs.submitNoScroll(e.target.dataset.submit);
  });

  $('.atomic-draggable-list').on('sortstop', function(e) {
    window.last_sort_stop = Date.now();
  });

  $('.atomic-draggable-list').on('sortupdate', function(e) {
    let dest_index = e.detail.destination.index;
    var pair_id_above;
    if (dest_index === 0) {
      // We're moving to the first position
      pair_id_above = 'none';
    } else {
      pair_id_above = e.detail.destination.items[dest_index - 1].dataset.pairid;
    }

    var pair_id_below;
    if (dest_index === e.detail.destination.items.length - 1) {
      // We're moving to the last position
      pair_id_below = 'none';
    } else {
      pair_id_below = e.detail.destination.items[dest_index + 1].dataset.pairid;
    }

    $('#moved-pair').val(e.detail.item.dataset.pairid);
    $('#pair-above').val(pair_id_above);
    $('#pair-below').val(pair_id_below);

    // Submit a position update
    myUjs.submitNoScroll('pair-move-form');
  });

  console.log("Sortable setup time (ms):", Date.now() - start_time);
});
