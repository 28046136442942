const HIDE_AFTER_MS = 2500;

document.addEventListener("turbolinks:load", function() {
  $('[data-guide]').each(function(_, elem) {
    let key = elem.dataset.key;
    if (key && localStorage.getItem('guide:' + key)) {
      // Already showed this guide; don't do it again
      return;
    }

    let target = $(elem);
    target.tooltip({
      title: elem.dataset.guide,
      trigger: 'manual'
    });

    target.tooltip('show');

    setTimeout(function() {
      target.tooltip('hide');

      // Ensure this shows only once
      if (key) {
        localStorage.setItem('guide:' + key, 'y');
      }
     }, HIDE_AFTER_MS
    );
  });
});
