const Rails = require('@rails/ujs');

module.exports.submitNoScroll = function(formId) {
  let scrollX = window.scrollX;
  let scrollY = window.scrollY;

  // Note: This will work if done on turbolinks:render but will
  // significantly slow down the rendering process!
  document.addEventListener('turbolinks:load', function(e) {
    window.scrollTo(scrollX, scrollY);

    // Unregister this listener so it can't fire again
    e.target.removeEventListener(e.type, arguments.callee);
  });

  // Submit the form using UJS
  Rails.fire(document.getElementById(formId), 'submit');
};
